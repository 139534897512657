<template>
  <div>

    <div class="navtop row-between mb20">
      <el-tabs v-model="activeName" type="card" @tab-click="resetList(1)">
        <el-tab-pane v-for="(item, index) in navList" :key="index" :name="item.value">
          <div slot="label" style="font-size: 14px">
            {{ item.label }}
            <span class="" v-if="statusNumber[item.value || 'all']">({{ statusNumber[item.value || "all"] }})</span>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div class="top-r flex1 row-end">
        <slot name="right"></slot>
      </div>
    </div>

    <el-form ref="form" :inline="true" :model="ruleForm" label-width="0px">
      <div class="row-between">
        <div class="row">
          <el-form-item>
            <el-input v-model="ruleForm.goods_name" placeholder="商品名称"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="ruleForm.bar_code" placeholder="湘雅物流编码"></el-input>
          </el-form-item>
          <!-- <el-form-item>
            <el-date-picker v-model="ruleForm.date" type="daterange" start-placeholder="开始日期" end-placeholder="结束日期"
              value-format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item> -->
        </div>

        <el-button type="primary" class="whtie submit" @click="resetList(1)">筛选</el-button>
        <el-button type="primary" class="whtie submit" @click="(dialogVisible = true)">
                批量审核
              </el-button>
       
      </div>
    </el-form>
    <template>
      <el-table  ref="multipleTable"  :data="exclusiveList"  tooltip-effect="dark"  style="width: 100%" @selection-change="handleSelectionChange"
         @sort-change="onSort" >
        <el-table-column type="selection" width="55"></el-table-column>
        <!-- <el-table-column label="序号" type="index" width="50">
      </el-table-column> -->
      <el-table-column prop="serial_number" label="序号" show-overflow-tooltip sortable="custom" :sort-orders="['ascending', 'descending', null]">
          <template slot-scope="scope">
            {{ scope.row.serial_number }}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="商品名称" width="400" show-overflow-tooltip>
          <template slot-scope="scope">
                <el-image style="width: 64px; height: 64px" :src="scope.row.image" fit="cover" class="ml20 mr20"></el-image>
                <router-link :to="`/prodetail?id=${scope.row.id}`" >{{ scope.row.name }}</router-link>
          </template>
        </el-table-column>
        <el-table-column prop="third_price" label="价格"  width="120">
          <template slot-scope="scope">
            ￥{{ scope.row.goods_item[0].third_price }}
          </template>
        </el-table-column>
        <el-table-column prop="examine_status_a" label="审核状态" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.examine_status_a ==1 ? '上柜' : scope.row.examine_status_a !=1 && scope.row.examine_status_a !=2 ? '未审核' : '下柜' }}
          </template>
        </el-table-column>
        <el-table-column prop="examine_status_a" label="审核状态" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button type="primary"  class="whtie submit" size="mini" round @click="
                (auditBox.goods_id = scope.row.id),
                (auditBox.type = 2),
                (dialogVisible = true)">
                审核
              </el-button>
            </template>
        </el-table-column>
        
      </el-table>
    </template>
    <el-empty description="暂无数据" v-if="!exclusiveList.length"></el-empty>
    <div class="row-end" v-if="exclusiveList.length">
      <make-pagination @resetList="resetList" :orderObject="orderObject" v-if="exclusiveList.length"></make-pagination>
    </div>
    <el-dialog title="专属商品审核" :visible.sync="dialogVisible" width="30%">
      <div class="auditBox_row">
        <div style="margin-bottom: 10px">审核状态：</div>
        <div>
          <el-radio-group v-model="auditBox.status" class="mb20">
            <el-radio :label="1" :value="1">上柜</el-radio>
            <el-radio :label="2" :value="2">下柜</el-radio>
          </el-radio-group>
          <el-input v-if="auditBox.status == 2" type="textarea" :rows="4" placeholder="请输入驳回内容（驳回时必填）"
            v-model="auditBox.reason" resize="none">
          </el-input>
        </div>
      </div>
      <!-- <div class="auditBox_row">
        <div style="margin-bottom: 10px">是否下架：</div>
        <div>
          <el-radio-group v-model="auditBox.offShelf" class="mb20">
            <el-radio :label="1" :value="1">是</el-radio>
            <el-radio :label="2" :value="2">否</el-radio>
          </el-radio-group>
        </div>
      </div> -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="setAudit()">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import NavTop from "../components/NavTop";
import MakePagination from "@/components/MakePagination";
export default {
  name: "",
  props: {},
  components: {
    navtop: NavTop,
    "make-pagination": MakePagination,
  },
  data() {
    return {
      ids:[],
      ruleForm: {
        bar_code: "",
        
        goods_name: "",
      },
      navList: [{
        label: "所有产品",
        value: "all",
      },
      {
        label: "上柜",
        value: "audited",
      },
      {
        label: "待审核",
        value: "unaudited",
      },
      // {
      //   label: "下架",
      //   value: "down",
      // },
      {
        label: "下柜",
        value: "reject",
      },
      ],
      handleNavList: [],
      statusNumber: {},
      checked: true,
      checkList: [0, 1],
      activeName: "",
      keyword: "",
      page_no: 1,
      page_size: 100,
      // 相关信息
      orderObject: {
        type: Object,
        default: () => { },
      },
      queryType: "all",
      exclusiveList: [],
      dialogVisible: false,
      auditBox: {
        status: 1,
        goods_id: [],
        type: "",
        reason: "",
        offShelf: 2,
        mall_type: ""
      },
      ruleForm: {
        spu: "",
        date: [],
        order_sn: "",
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    
    handleSelectionChange(selection) {
      let { auditBox } = this;
      if (selection) {
        let arr = [];
        arr = selection.map(selection=>selection.id);
        console.log("arr", arr)

        //this.exclusiveList = data
        //this.selection =auditBox.goods_id;
        //this.ids.push(this.auditBox.goods_id);
        //console.log(selection);
        auditBox.goods_id = arr;
        //JSON.parse(JSON.stringify(this.arr))
        auditBox.type =2;
        auditBox.status =1;
        console.log(auditBox);
      } else {
        this.selection = [];
        //console.log(selection);
      }
      //console.log(selection);
    },
    async setAudit() {
      let { auditBox } = this;
          console.log("auditBox",auditBox)
      auditBox.mall_type = this.$store.getters.commonData.mall_type;
      let api =
        auditBox.type == 1
          ? this.$api.examine_status_bApi
          : this.$api.examine_status_aApi;
      let { code, msg } = await api(auditBox);
      if (code == 1) {
        this.$message({
          message: msg,
          type: "success",
        });
        this.auditBox.reason = "";
        this.dialogVisible = false;
      }
      this.resetList();
      // this.purchaseOrderNumber()
    },
    /**
     * @description 获取售后列表
     * @returns void
     */
    // getExclusivgoodsList() {
    //   let my_type = this.$store.getters.commonData.mall_type;
    //   this.$api
    //     .getExclusivgoodsListApi({
    //       type: this.queryType,
    //       page_no: this.page_no,
    //       page_size: this.page_size,
    //       mall_type: my_type,
    //     })
    //     .then((res) => {
    //       this.exclusiveList = res.data.lists;
    //       let { lists, count, page_size: size, page_no: page } = res.data;
    //       this.orderObject = res.data;
    //       this.orderObject = {
    //         page,
    //         size,
    //         count,
    //         lists,
    //       };
    //     });
    // },
    offShelf(goods_id) {
      this.$confirm("确定下架吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        let mall_type = this.$store.getters.commonData.mall_type;
        this.$api
          .offShelfApi({
            goods_id: goods_id,
            mall_type: mall_type,
          })
          .then((res) => {
            if (res.code == 1) {
              this.$message({
                message: "下架成功",
                type: "success",
              });

              this.dialogVisible = false;
            }
            this.getExclusivgoodsList();
          });
      });
    },

    resetList(e) {
      this.page_no = e;
      this.getExclusivgoodsList();
    },

    /**
   * @description 获取我的订单数据
   */
    getExclusiveStatusNumber() {
      this.$api.getExclusiveStatusNumberApi().then((res) => {
        this.statusNumber = res.data;
      });
    },    /**
     * @description 获取订单列表
     * @returns void
     */
    getExclusivgoodsList() {
      this.getExclusiveStatusNumber();
      let { page_no, page_size, activeName: type } = this;
      let { goods_name,bar_code } = this.ruleForm;
      this.$api
        .getExclusivgoodsListApi({
          type,
          page_no,
          page_size,
          bar_code,
          goods_name,        
        })
        .then((res) => {
          this.exclusiveList = res.data.lists;
          let { lists, count, page_size: size, page_no: page } = res.data;
          this.orderObject = res.data;
          this.orderObject = {
            page,
            size,
            count,
            lists,
          };
        });
    },
  },
  created() {
    this.getExclusivgoodsList();
  },
  mounted() { },
  beforeDestroy() { },
  
};
</script>
<style lang="scss" scoped>
.screen {
  .search-box {
    height: 45px;
    border: 2px solid #c7c7c7;
    background-color: #c7c7c7;

    .el-input {
      width: 262px;
    }

    /deep/ .el-input__inner {
      border: none;
      height: 41px;
      line-height: 1;
      border-radius: 0;
    }

    button {
      height: 41px;
      line-height: 1;
      width: 107px;
      background-color: #c7c7c7;
      cursor: pointer;
    }
  }
}

.order-list {
  overflow: hidden;

  .order-item {
    /deep/ .el-checkbox__label {
      display: none;
    }

    .flex5 {
      flex: 5;
    }
    .flex4 {
      flex: 4;
    }
    .flex3 {
      flex: 3;
    }
    .flex2 {
      flex: 2;
    }

    >div {
      flex-shrink: 0;
      overflow: hidden;
    }

    &.item-th {
      height: 44px;
      background: $-color-center;
      //   padding-right: 25px;
      margin-bottom: 15px;

      .pd {
        padding-left: 56px;
        box-sizing: border-box;
      }
    }

    &.item-pro {
      box-shadow: 0px 1px 0px 0px rgba(184, 184, 184, 0.4);

      .pro-title {
        background-color: $-color-border;
        padding: 6px 0;

        .service {
          margin-right: 36px;

          img {
            width: 30px;
            height: 30px;
            margin-right: 3px;
          }
        }
      }

      .pro-content {
        background-color: $-color-center;

        // padding-right: 25px;
        >div {
          flex-shrink: 0;
          overflow: hidden;
        }

        .pro-info {
          padding: 30px 0;
            
          .info-detail {
            // padding-right: 20px;
            overflow: hidden;
          }

          img {
            width: 64px;
            height: 64px;
          }
        }

        .pro-price {
          align-items: center;
        }

        .pro-status {
          text-align: center;
        }

        button {
          width: 90px;
          height: 35px;
          cursor: pointer;
        }

        .pro-other {
          align-items: center;

          .time {
            margin-bottom: 5px;

            img {
              width: 14px;
              height: 14px;
            }
          }
        }
      }
    }
  }
}

/deep/ .el-dialog__body {
  padding: 20px;

  .el-radio__label {
    font-size: 16px;
  }
}
</style>
